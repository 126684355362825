import React from 'react';
import { ExternalLinkIcon } from './SvgIcon';

const ImageAttribution = ({ featured, hero }) => {
  let imgType;

  function renderImageWithLabel(imgType, url, label) {
    return (
      <p>
        {imgType} image by{' '}
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
          <span className="sr-only">, opens in a new window</span>
        </a>
      </p>
    );
  }

  function renderImageWithoutLabel(imgType, url) {
    return (
      <p>
        {imgType} image{' '}
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="external-link"
        >
          source
        </a>
      </p>
    );
  }

  function heroImageAttribution() {
    if (!!hero) {
      imgType = 'Hero';
      if (!!hero.attributionUrl && !!hero.attributionLabel) {
        return renderImageWithLabel(
          imgType,
          hero.attributionUrl,
          hero.attributionLabel
        );
      } else if (!!hero.attributionUrl && !hero.attributionLabel) {
        return renderImageWithoutLabel(imgType, hero.attributionUrl);
      }
    }
  }

  function featuredImageAttribution() {
    if (!!featured) {
      if (hero && !hero.attributionLabel && !hero.attributionUrl) {
        imgType = 'Hero';
      } else if (!hero) {
        imgType = 'Hero';
      } else {
        imgType = 'Featured';
      }

      if (!!featured.attributionUrl && !!featured.attributionLabel) {
        return renderImageWithLabel(
          imgType,
          featured.attributionUrl,
          featured.attributionLabel
        );
      } else if (featured.attributionUrl && !featured.attributionLabel) {
        return renderImageWithoutLabel(imgType, featured.attributionUrl);
      }
    }
  }

  return (
    <span>
      {hero ? heroImageAttribution() : ''}
      {featured ? featuredImageAttribution() : ''}
    </span>
  );
};

export default ImageAttribution;
