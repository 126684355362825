import React from 'react';
import PropTypes from 'prop-types';
import AuthorBox from './AuthorBox';

const AuthorsListing = ({ title, names, full, AuthorNameTag }) => {
  let authors = new Map();

  if (full) {
    full.forEach(({ node: author }) => {
      authors.set(author.frontmatter.name, author);
    });
  }

  if (names) {
    names.forEach(name => {
      if (!authors.get(name)) {
        authors.set(name, { id: name, frontmatter: { name: name } });
      }
    });
  }
  let authorsArray = Array.from(authors.values());
  if (authorsArray.length > 0) {
    return (
      <section className="grid-container">
        <div className="col-md-10 col-xs-12">
          {title && <h2 className="author-listing-title">{title}</h2>}
          {authorsArray
            .sort((a, b) => a.frontmatter.name.localeCompare(b.frontmatter.name))
            .map((author, index) => (
              <AuthorBox
                key={author.id}
                index={index}
                name={author.frontmatter.name}
                image={author.frontmatter.featuredimage}
                bio={author.excerpt}
                twitter={author.frontmatter.twitter}
                github={author.frontmatter.github}
                linkedin={author.frontmatter.linkedin}
                AuthorNameTag={AuthorNameTag}
              />
            ))}
          </div>
      </section>
    );
  } else {
    return null;
  }
};

AuthorsListing.propTypes = {
  title: PropTypes.string,
  names: PropTypes.array,
  full: PropTypes.array,
};

export default AuthorsListing;
