import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';
import { kebabCase } from 'lodash';

class CategoriesBox extends React.Component {
  render() {
    const { data: categories } = this.props;

    return (
      <ul className={`taglist tags-blocks taglink-pink ${this.props.classes || ''}`}>
        {categories.map(({ node: cat }) => (
          <li key={cat.frontmatter.tag}>
            <Link
              to={`/blog/${kebabCase(cat.frontmatter.tag)}/`}
            >
              {cat.frontmatter.title}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

CategoriesBox.propTypes = {
  data: PropTypes.array,
};

export default props => (
  <StaticQuery
    query={graphql`
      query CategoriesBoxQuery {
        allMarkdownRemark(
          sort: { fields: [frontmatter___title], order: ASC }
          filter: { 
            frontmatter: { templateKey: { eq: "category" }, 
            current: { eq: true }
            }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                tag
              }
            }
          }
        }
      }
    `}
    render={data => (
      <CategoriesBox
        classes={props.classes}
        data={data.allMarkdownRemark.edges.filter(c =>
          props.tags.includes(c.node.frontmatter.tag)
        )}
      />
    )}
  />
);
