import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from './SvgIcon';

const SocialLinks = ({ label, url }) => (
  <section className="social-links">
    <div className="social-links-wrapper">
      <span className="social-links-label">{label}</span>
      <a
        className="twitter hover-jump-small-child"
        href={`https://twitter.com/intent/tweet?url=${url}&via=WunThompsonTECH`}
        target="_blank"
        title="Share on Twitter"
        rel="noopener noreferrer"
      >
        <SvgIcon className="square-medium" useHref="icon-twitter" />
      </a>
      <a
        className="facebook hover-jump-small-child"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`}
        target="_blank"
        title="Share on Facebook"
        rel="noopener noreferrer"
      >
        <SvgIcon className="square-medium" useHref="icon-facebook" />
      </a>
      <a
        className="linkedin hover-jump-small-child"
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
        target="_blank"
        title="Share on LinkedIn"
        rel="noopener noreferrer"
      >
        <SvgIcon className="square-medium" useHref="icon-linkedin" />
      </a>
    </div>
  </section>
);

SocialLinks.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default SocialLinks;
