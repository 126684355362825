import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from './SvgIcon';

const socialMedia = {
  github: {
    iconHref:"icon-github",
    linkBase:"https://github.com/"
  },
  twitter: {
    iconHref:"icon-twitter",
    linkBase:"https://twitter.com/"
  },
  linkedin: {
    iconHref:"icon-linkedin",
    linkBase:"https://www.linkedin.com/in/"
  }
}

const AuthorSocial = ({ name, github, twitter, linkedin }) => {

  const SocialIcon = ({ type, username }) => {
    const socialWebsite = socialMedia[type];
    return username && socialWebsite
      ? <a
        href={`${socialWebsite.linkBase}${username}`}
        target="_blank"
        rel="noopener noreferrer"
        className="hover-jump-small-child"
      >
        <SvgIcon
          className="square-small"
          useHref={socialWebsite.iconHref}
        />
        <span className="sr-only">
          {name}'s {type}, opens in a new window
        </span>
      </a>
      : null
  }

  return (
    <div className="author-social">
      <SocialIcon type="github" username={github}/>
      <SocialIcon type="twitter" username={twitter}/>
      <SocialIcon type="linkedin" username={linkedin}/>
    </div>
  );
};

AuthorSocial.propTypes = {
  name: PropTypes.string.isRequired,
  github: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string
};

export default AuthorSocial;
