import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import '../styles/prismjs.css';
import TagsBox from '../components/TagsBox';
import { kebabCase } from 'lodash';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import AuthorsListing from '../components/AuthorsListing';
import CategoriesBox from '../components/CategoriesBox';
import ImageAttribution from '../components/ImageAttribution';
import { GatsbySeo, BlogPostJsonLd } from 'gatsby-plugin-next-seo';
import useSiteMetadata from '../components/SiteMetadata';
import SocialLinks from '../components/SocialLinks';
import { ExternalLinkIcon } from '../components/SvgIcon';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  summary,
  title,
  publicationDate,
  authors,
  timeToRead,
  authorsListing,
  tagsBox,
  categoriesBox,
  seo,
  heroImage,
  featuredimage,
  canonicalUrl,
  pageLocation,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="main blog-post-page container-narrow blog-page">
      <section className="hero">
        {heroImage ? (
          <div className="image-wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: heroImage.src,
                alt: heroImage.alt ? heroImage.alt : '',
              }}
            />
          </div>
        ) : (
          <div className="image-wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage.src,
                alt: featuredimage.alt ? featuredimage.alt : '',
              }}
            />
          </div>
        )}
      </section>
      <section>
      {seo || ''}
        <div className="grid-container">
          <div className="col-md-10 col-xs-12">
            {categoriesBox || ''}
          </div>
        </div>
          <div className="relative grid-container">
            <div className="col-md-10 col-xs-12">
              <div className="data-wrapper">
                <span className="publication-date">{publicationDate}</span>
                <span className="time-to-read">{timeToRead} min read</span>
              </div>
              <h1 className="hero-text-title">{title}</h1>
              <p className="hero-text-author">
                by{' '}
                {authors.map((author, index) => (
                  <span key={index}>
                    {index ? ', ' : ' '}
                    <Link
                      className="taglink"
                      to={`/authors/${kebabCase(author)}/`}
                    >
                      {author}
                    </Link>
                  </span>
                ))}
              </p>
              <div className="container-article-body">
                <h2 className="summary">{summary}</h2>
                <PostContent content={content} className="post-content blog" />
                <ImageAttribution hero={heroImage} featured={featuredimage} />
                {canonicalUrl ? (
                  <p>
                    This post was originally published on:{' '}
                    <a
                      href={canonicalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover-border-bottom-current-color hover-wobble-svg"
                    >
                      {canonicalUrl}
                      <span className="sr-only">, opens in a new window</span>
                      <ExternalLinkIcon />
                    </a>
                  </p>
                ) : null}
                {tagsBox || ''}
              </div>
            </div>
            <div className="grid-container">
              <div className="col-md-10 col-xs-12">
                <SocialLinks label="Share this event:" url={pageLocation} />
              </div>
            </div>
          </div>
          {authorsListing || ''}
      </section>
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  summary: PropTypes.string,
  title: PropTypes.string.isRequired,
  publicationDate: PropTypes.string.isRequired,
  authors: PropTypes.array.isRequired,
  timeToRead: PropTypes.number,
  authorsListing: PropTypes.object,
  tagsBox: PropTypes.object,
  categoriesBox: PropTypes.object,
  helmet: PropTypes.object,
  heroImage: PropTypes.object,
  featuredimage: PropTypes.object,
  canonicalUrl: PropTypes.string,
  pageLocation: PropTypes.string,
};

const BlogPost = ({ data }) => {
  const { post } = data;
  const { frontmatter } = post;
  const { edges: authors } = data.authors;
  const { title } = useSiteMetadata();

  const seoDescription =
    frontmatter.summary.length > 120
      ? `${frontmatter.summary.substr(0, 117)}...`
      : frontmatter.summary;

  const pageLocation = `${process.env.PAGE_DOMAIN}${post.fields.slug}`;
  const postImage = `${process.env.PAGE_DOMAIN}${frontmatter.socialImage.src.childImageSharp.fluid.src}`;

  return (
    <Layout pageType="DetailsPage">
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        publicationDate={frontmatter.date}
        summary={frontmatter.summary}
        tagsBox={<TagsBox title={'Tags'} tags={frontmatter.tags} />}
        categoriesBox={
          <CategoriesBox tags={frontmatter.tags} />
        }
        authorsListing={
          <AuthorsListing
            names={frontmatter.authors}
            AuthorNameTag="h3"
            full={authors}
            title={`About the author${
              frontmatter.authors.length > 1 ? 's' : ''
            }`}
          />
        }
        title={frontmatter.title}
        heroImage={frontmatter.heroImage}
        featuredimage={frontmatter.featuredimage}
        socialImage={frontmatter.socialImage}
        authors={frontmatter.authors}
        timeToRead={post.timeToRead}
        canonicalUrl={frontmatter.canonical}
        pageLocation={pageLocation}
        seo={
          <>
            <GatsbySeo
              title={frontmatter.title}
              titleTemplate={`%s | ${title}`}
              description={seoDescription}
              canonical={frontmatter.canonical || pageLocation}
              openGraph={{
                title: frontmatter.title,
                description: frontmatter.summary,
                images: [
                  {
                    url: postImage,
                  },
                ],
                url: pageLocation,
              }}
            />
            <BlogPostJsonLd
              url={pageLocation}
              title={frontmatter.title}
              images={[postImage]}
              datePublished={frontmatter.date}
              authorName={frontmatter.authors.join(', ')}
              description={seoDescription}
              publisherName="Cognifide"
            />
          </>
        }
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $people: [String!]!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        summary
        tags
        canonical
        authors
      }
      fields {
        slug
      }
      ...featuredImageWide
      ...heroImageWide
      ...socialImage
    }
    authors: allMarkdownRemark(
      filter: { frontmatter: { name: { in: $people } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          ...AuthorBoxFragment
        }
      }
    }
  }
`;
