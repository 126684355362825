import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import { graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import AuthorSocial from './AuthorSocial';

const AuthorBox = ({
  name,
  image,
  bio,
  noOfPosts,
  twitter,
  github,
  linkedin,
  index,
  AuthorNameTag = 'p',
}) => {
  return (
    <section
      className={`section-author ${
        index === 0 ? 'section-author-first' : ''
        }`}
    >
      <div className="section-author-inner grid-container">
        <div
          className={`featured-thumbnail col-xs-12 col-sm-5 ${image ? '' : 'custom-thumbnail'}`}
        >
          {image ? (
            <PreviewCompatibleImage
              imageInfo={{
                image: image.src,
                alt: name,
              }}
            />
          ) : (
              <PreviewCompatibleImage
                imageInfo={{
                  image: '/img/author-placeholder.png',
                  alt: name,
                }}
              />
            )}
        </div>

        <div
          className={`section-author-bio col-xs-12 col-sm-7 ${
            bio ? '' : 'section-author-bio-empty'
            }`}
        >
          <div className="author-head-container">
            <AuthorNameTag className="section-author-bio-title">
              <Link to={`/authors/${kebabCase(name)}/`}>
                {name}
              </Link>
            </AuthorNameTag>
            <AuthorSocial name={name} github={github} twitter={twitter} linkedin={linkedin} />
          </div>
          {bio ? (
            <p className="author-bio">{bio}</p>
          ) : (
              <p>
                If you are interested in {name}’s work, please use the link below.
            </p>
            )}

          <p className="section-author-bio-more">
            <Link
              to={`/authors/${kebabCase(name)}/`}
            >
              See {name}'s profile
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

AuthorBox.propTypes = {
  name: PropTypes.string.isRequired,
  bio: PropTypes.string,
  image: PropTypes.object,
  github: PropTypes.string,
  twitter: PropTypes.string,
  noOfPosts: PropTypes.number,
  index: PropTypes.number,
};

export default AuthorBox;

export const query = graphql`
  fragment AuthorBoxFragment on MarkdownRemark {
    frontmatter {
      name
      twitter
      github
      linkedin
    }
    ...featuredImageAuthor
  }
`;
